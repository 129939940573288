import React, { useState } from 'react';
import { Box, Chip, TextField, Typography } from '@mui/material';
// import { GridRowParams } from '@mui/x-data-grid';
import { IAppointmentInvoiceSummary } from '../appointment-invoice-summary.interface';
import { useBulkUpdateInvoices } from '../use-bulk-update-invoices';
import { AppDataGrid } from '@components/AppDataGrid';
import { columns } from '../columns';
import { AppLoadingButton } from '@components/AppLoadingButton';
// import { BooleanChip } from '@utils/render-boolean';
import { createColumnFactory } from '@utils/create-column.factory';
import { EmptyCell } from '@utils/empty-cell';

type Props = {
  rows: IAppointmentInvoiceSummary[];
};

type SummaryWithResult = IAppointmentInvoiceSummary & { result: 'success' | 'error' | null };

const createAppointmentInvoiceSummaryColumn = createColumnFactory<SummaryWithResult>();

function getResult(id: number, acceptedIds: number[], rejectedIds: number[]): 'success' | 'error' | null {
  if (rejectedIds.includes(id)) {
    return 'error';
  }
  if (acceptedIds.includes(id)) {
    return 'success';
  }
  return null;
}

export const AddSupplierInvoice: React.FC<Props> = ({ rows }) => {
  const { handleBulkUpdateInvoices, isLoading } = useBulkUpdateInvoices();
  const [acceptedIds, setAcceptedIds] = useState<Array<number>>([]);
  const [rejectedIds, setRejectedIds] = useState<Array<number>>([]);
  const [invoice, setInvoice] = useState('');

  const rowsWithResult: Array<SummaryWithResult> = rows.map((row) => ({
    result: getResult(row.id, acceptedIds, rejectedIds),
    ...row,
  }));

  const columnsWithResult = columns.concat(
    createAppointmentInvoiceSummaryColumn('result', {
      headerName: 'Result',
      width: 100,
      renderCell: ({ row }) =>
        row.result ? (
          <Chip
            label={row.result === 'error' ? 'Rejected' : 'Accepted'}
            size="small"
            variant="outlined"
            color={row.result}
            className="MuiChip-status"
          />
        ) : (
          EmptyCell
        ),
    }),
  );

  const handleAction = async (): Promise<void> => {
    const result = await handleBulkUpdateInvoices(
      rows.map(({ id }) => id),
      'invoice',
    );
    if (result) {
      setAcceptedIds(result.acceptedAppointmentsIds);
      setRejectedIds(result.rejectedAppointmentsIds);
    }
  };

  // const getRowClassName = (params: GridRowParams): string => {
  //   if (rejectedIds.includes(params.id as number)) {
  //     return 'MuiDataGrid-row-error';
  //   }
  //   if (acceptedIds.includes(params.id as number)) {
  //     return 'MuiDataGrid-row-success';
  //   }
  //   return '';
  // };

  return (
    <Box sx={{ height: '100%' }}>
      <Typography variant="h4" component="h1">
        Add Supplier Invoice
      </Typography>
      <TextField
        variant="outlined"
        value={invoice}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          setInvoice(event.target.value);
        }}
      />
      <AppLoadingButton isLoading={isLoading} disabled={invoice === ''} onClick={handleAction}>
        Add Invoice
      </AppLoadingButton>
      <AppDataGrid
        rows={rowsWithResult}
        columns={columnsWithResult}
        sx={{ mt: 1, height: '80vh' }}
        // fullHeight
        hideFooter
        // getRowClassName={getRowClassName}
      />
    </Box>
  );
};
