import { identity } from 'lodash';
import { IOrder } from '@features/order/order.interface';
import { nullToString } from '@utils/null-to-string';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { DateFilterOptions, DateFilterType } from './date.type';
import { emptyStringToNull } from '@utils/empty-string-to-null';

export type FormModel = {
  orderStatusId: IOrder['orderStatusId'][];
  date: DateFilterType | null;
  query: string;
};

export type ApiModel = {
  orderStatusId: IOrder['orderStatusId'][];
  date: DateFilterType | null;
  query: string | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', multiple: true, initial: [] },
  date: { type: 'enum', options: DateFilterOptions, initial: null },
  query: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderStatusId: nullToString,
  date: identity,
  query: emptyStringToNull,
});
