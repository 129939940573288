import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import { MuiFileInputProps } from 'mui-file-input';
import { Stack } from '@mui/material';
import { OpenMultipleFiles } from './OpenMultipleFiles';
import { ControlledUploadFileField } from './ControlledUploadFileField';
import { FileDetails } from '@models/file-details.type';

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  fileDetails?: FileDetails[] | string | null;
  buttonLabel?: string;
  isPreview?: boolean;
  isMultiple?: boolean;
} & MuiFileInputProps;

export const ControlledFileField: <T extends FieldValues>(props: Props<T>) => JSX.Element = ({
  control,
  name,
  label,
  fileDetails,
  buttonLabel = 'Download File',
  isPreview = false,
  isMultiple = false,
  ...props
}) => {
  return (
    <Stack direction="row" spacing={2}>
      <ControlledUploadFileField control={control} name={name} label={label} isMultiple={isMultiple} {...props} />
      {fileDetails && (
        <OpenMultipleFiles
          fullWidth
          fileDetails={fileDetails}
          text={buttonLabel}
          isPreview={isPreview}
          variant="outlined"
          size="medium"
        />
      )}
    </Stack>
  );
};
