import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useGetOrderContactRecordsQuery } from '@api/api-slice';
import { IActionRecord } from '@features/action-records/action-record.interface';
import { IPaginable } from '@features/pagination/paginable.interface';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { RecordModelNames } from '@features/action-records/record-model-name.type';

export type HookResult = RequestStatusFlags & IPaginable<IActionRecord>;

export function useOrderContactRecords(id: number, count: number, skip: number): HookResult {
  return getPaginationResult(useGetOrderContactRecordsQuery({ count, skip, modelName: RecordModelNames.Order, id }));
}
