import React from 'react';
import { Box, Card, IconButton, Link, Stack, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Routes } from '@routes/routes';
import { useLogin } from './use-login';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useToggle } from '@hooks/use-toggle';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { ControlledTextField } from '@components/inputs/ControlledTextField';

export const Login: React.FC = () => {
  const { submitHandler, control, isLoading, isSubmitDisabled } = useLogin();
  const [isPasswordVisible, toggleIsPasswordVisible] = useToggle();

  return (
    <Box sx={{ background: '#B2BFCB', height: '100vh', padding: '128px 0' }}>
      <Card sx={{ padding: 6, paddingBottom: 3, width: 290, margin: 'auto' }}>
        <Typography variant="h4">Let&apos;s get started!</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Login to access your account.
        </Typography>
        <form onSubmit={submitHandler}>
          <Stack direction="column" spacing={2} sx={{ marginTop: 3 }}>
            <ControlledTextField control={control} name="email" label="Email" fullWidth />
            <Box sx={{ position: 'relative' }}>
              <ControlledTextField
                control={control}
                name="password"
                label="Password"
                type={isPasswordVisible ? 'text' : 'password'}
                fullWidth
              />
              <IconButton onClick={toggleIsPasswordVisible} sx={{ position: 'absolute', right: 0 }}>
                {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Box>
            <AppLoadingButton type="submit" isLoading={isLoading} disabled={isSubmitDisabled}>
              Login
            </AppLoadingButton>
            <Link component={NavLink} to={Routes.reset}>
              Forgot password?
            </Link>
          </Stack>
        </form>
      </Card>
    </Box>
  );
};
