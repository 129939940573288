import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useTutor } from './use-tutor';
import { BackendLabelGroup } from '@features/backend-label/BackendLabelGroup';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { EmptyCell } from '@utils/empty-cell';
import { BooleanChip } from '@utils/render-boolean';
import { getPartialByKeys } from '@utils/get-partial-by-keys';
import { AppDetails, DetailsConfig } from '@components/AppDetails';

export const TutorDetails: React.FC = () => {
  const { tutor, isLoading } = useTutor();

  if (isLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  if (!tutor) {
    return <Alert severity="warning">Tutor not found.</Alert>;
  }

  const tutorDetails = getPartialByKeys(tutor, [
    'firstName',
    'lastName',
    'email',
    'activeSupportAssigned',
    'qualification',
    'availability',
    'employmentType',
    'isActive',
    'calendlyLink',
    'feedbackLast',
    'feedbackAverageWeek',
    'feedbackAverageMonth',
    'feedbackAverageTotal',
    'about',
  ]);

  const config: DetailsConfig<typeof tutorDetails> = {
    firstName: { label: 'Name', render: (value) => value },
    lastName: { label: 'Last name', render: (value) => value },
    email: { label: 'Email', render: (value) => value },
    activeSupportAssigned: { label: 'Active Support Assigned', render: (value) => value.toString() },
    qualification: {
      label: 'Qualification',
      render: (value) => <BackendLabelGroup labels={value} optionKey="qualificationOptions" />,
    },
    availability: {
      label: 'Availability',
      render: (value) => <BackendLabel value={value} optionKey="intensityOptions" />,
    },
    employmentType: { label: 'Employment Type', render: (value) => value },
    isActive: { label: 'Active', render: (value) => <BooleanChip value={value} /> },
    calendlyLink: { label: 'Calendly Link', render: (value) => (value ? <a href={value}>{value}</a> : '—') },
    feedbackLast: { label: 'Feedback Last', render: (value) => value?.toString() ?? EmptyCell },
    feedbackAverageWeek: { label: 'Feedback Average Week', render: (value) => value?.toString() ?? EmptyCell },
    feedbackAverageMonth: { label: 'Feedback Average Month', render: (value) => value?.toString() ?? EmptyCell },
    feedbackAverageTotal: { label: 'Feedback Average Total', render: (value) => value?.toString() ?? EmptyCell },
    about: { label: 'Notes', render: (value) => value },
  };

  return (
    <Box>
      <Typography variant="h4" component="h1">
        Tutor Details
      </Typography>
      <AppDetails model={tutorDetails} config={config} />
    </Box>
  );
};
