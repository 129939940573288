import { Moment } from 'moment-timezone';
import { identity } from 'lodash';
import { nullToString } from '@utils/null-to-string';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { ISODateString } from '@utils/dates/iso-string.type';
import { toISODay } from '@utils/dates/to-iso-day';
import { emptyStringToNull } from '@utils/empty-string-to-null';

export type FormModel = {
  startDate: Moment | null;
  endDate: Moment | null;
  organisationId: Array<number | null>;
  assessorId: Array<number | null>;
  funderInvoice: string | null;
  supplierInvoice: string | null;
  isPaid: boolean;
  query: string;
  assessmentCentreId: Array<number | null>;
  orderStatus: Array<number | null>;
  appointmentId: number | null;
};

export type ApiModel = {
  startDate: ISODateString;
  endDate: ISODateString;
  organisationId: Array<number | null>;
  assessorId: Array<number | null>;
  funderInvoice: string | null;
  supplierInvoice: string | null;
  isPaid: string | null;
  query: string | null;
  assessmentCentreId: Array<number | null>;
  orderStatus: Array<number | null>;
  appointmentId: number | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  startDate: { type: 'date', initial: null },
  endDate: { type: 'date', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  assessorId: { type: 'backend-label', optionKey: 'assessorOptions', multiple: true, initial: [] },
  funderInvoice: { type: 'transportable', initial: '' },
  supplierInvoice: { type: 'transportable', initial: '' },
  isPaid: { type: 'transportable', initial: null },
  query: { type: 'transportable', initial: '' },
  assessmentCentreId: { type: 'backend-label', optionKey: 'assessmentCentreOptions', multiple: true, initial: [] },
  orderStatus: { type: 'backend-label', optionKey: 'statusOptions', multiple: true, initial: [] },
  appointmentId: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  startDate: toISODay,
  endDate: toISODay,
  organisationId: nullToString,
  assessorId: nullToString,
  funderInvoice: emptyStringToNull,
  supplierInvoice: emptyStringToNull,
  isPaid: emptyStringToNull,
  query: emptyStringToNull,
  assessmentCentreId: nullToString,
  orderStatus: nullToString,
  appointmentId: identity,
});
