import React from 'react';
import { Button } from '@mui/material';
import { AppDrawer } from '@components/AppDrawer';
import { useToggle } from '@hooks/use-toggle';
import { QuoteDrawer } from '@features/quote/QuoteDrawer';
import { useAppSelector } from '@store/use-app-selector';
import { selectCustomerState } from '@features/customer/customer.slice';
import { WhyDisabled } from '@components/WhyDisabled';

type Props = {
  quoteId: string | null;
  orderId: number;
  isReadonly?: true;
};

export const OrderQuote: React.FC<Props> = ({ orderId, quoteId, isReadonly }) => {
  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle();
  const customer = useAppSelector((state) => selectCustomerState(state));
  const isEdit = quoteId !== null;

  const buttonLabel = isEdit ? 'Edit Quote' : 'Create Quote';
  const disabledTitle = isReadonly ? 'Quote is read only' : 'First set customer CRN';

  const isCreatable = customer?.crn !== null;
  const isDisabledByCrn = !isEdit && !isCreatable;
  const isDisabled = isReadonly ? !isEdit : isDisabledByCrn;

  return (
    <>
      <WhyDisabled disabled={isDisabled} title={disabledTitle}>
        <Button variant="outlined" fullWidth disabled={isDisabled} onClick={toggleIsDrawerOpen}>
          {buttonLabel}
        </Button>
      </WhyDisabled>
      <AppDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleIsDrawerOpen}>
        <QuoteDrawer orderId={orderId} quoteId={quoteId} isReadonly={isReadonly} />
      </AppDrawer>
    </>
  );
};
