import { AppPaper } from '@components/AppPaper';
import React, { FC } from 'react';
import { HepDetails } from './HepDetails';
import { useHep } from './use-hep';
import { Alert, CircularProgress } from '@mui/material';
import { HepInfo } from './HepInfo';

export const HepPage: FC = () => {
  const { hep, isLoading } = useHep();

  if (isLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  if (!hep) {
    return <Alert severity="warning">Hep not found.</Alert>;
  }

  return (
    <AppPaper>
      <HepDetails hep={hep} />
      <HepInfo hep={hep} />
    </AppPaper>
  );
};
