import React from 'react';
import { useFilteredAssessorUtilisation } from './filter-assessor-utilisation-form/use-filtered-assessor-utilisation';
import { AppPaper } from '@components/AppPaper';
import {
  Alert,
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AssessorAvailability } from './AssessorAvailability';
import { UtilisationSummary } from './UtilisationSummary';
import { SlotsHeader } from './SlotsHeader';
import { FilterAssessorUtilisationForm } from './filter-assessor-utilisation-form/FilterAssessorUtilisationForm';

const Weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const AssessorUtilisationPage: React.FC = () => {
  const { assessorAvailability, slotSummary, isLoading } = useFilteredAssessorUtilisation();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!assessorAvailability || !slotSummary) {
    return <Alert severity="warning">Utilisation information not found.</Alert>;
  }

  return (
    <AppPaper>
      <Stack direction="row">
        <Typography variant="h4" component="h1">
          Assessor Utilisation
        </Typography>
        <Box sx={{ flexGrow: 1, ml: 2 }}>
          <FilterAssessorUtilisationForm />
        </Box>
      </Stack>
      <TableContainer component={Box} sx={{ mt: 1 }}>
        <Table sx={{ minWidth: 650, border: `1px solid}` }} size="small" className="MuiTable-root-grouped">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} className="MuiTableCell-compact" />
              {Weekdays.map((day) => (
                <TableCell
                  key={`name-${day}`}
                  colSpan={7}
                  align="center"
                  sx={{ borderLeft: '1px solid' }}
                  className="MuiTableCell-compact"
                >
                  {day}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell sx={{ verticalAlign: 'bottom' }}>Assessor</TableCell>
              <TableCell sx={{ verticalAlign: 'bottom' }}>Employment</TableCell>
              <TableCell sx={{ verticalAlign: 'bottom' }}>Organisation</TableCell>
              {Weekdays.map((day) => (
                <SlotsHeader key={`time-${day}`} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <AssessorAvailability assessorAvailability={assessorAvailability} />
            <UtilisationSummary title="Available Slots" summary={slotSummary.availableSlots} />
            <UtilisationSummary title="Booking Per Slot" summary={slotSummary.bookingPerSlot} />
            <UtilisationSummary title="Canceled Booking Per Slot" summary={slotSummary.canceledBookingPerSlot} />
            <UtilisationSummary title="Unbooked Slots" summary={slotSummary.unbookedSlots} />
          </TableBody>
        </Table>
      </TableContainer>
    </AppPaper>
  );
};
