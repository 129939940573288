import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useGetAllocationsQuery } from '@api/api-slice';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { IPaginable } from '@features/pagination/paginable.interface';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { IAllocation } from '@features/allocations/allocation.interface';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { sanitize, serialize } from './form-config';

type HookResult = RequestStatusFlags & IPaginable<IAllocation>;

export function useFilteredAllocations(urlSearchParams: URLSearchParams, count: number, skip: number): HookResult {
  const backendLabels = useAppBackendLabels(['supportOptions', 'tutorOptions', 'statusOptions']);
  const params = serialize(sanitize(deserializeURLSearchParams(urlSearchParams), backendLabels));
  return getPaginationResult(useGetAllocationsQuery({ count, skip, ...params }));
}
