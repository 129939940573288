import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Link, Stack, Typography } from '@mui/material';
import { AppPaper } from '@components/AppPaper';
import { GridColDef, GridFooter, GridSortModel } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { Routes } from '@routes/routes';
import { createColumnFactory } from '@utils/create-column.factory';
import { formatDateTimeAndDuration } from '@utils/dates/format-date-time-and-duration';
import { formatDate } from '@utils/dates/format-date';
import { useFilteredSessions } from './filter-sessions-form/use-filtered-sessions';
import { ISessionSummary } from '@features/sessions/session.interface';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { FilterSessionsForm } from './filter-sessions-form/FilterSessionsForm';
import { BooleanChip } from '@utils/render-boolean';
import { CopyTableToClipboard } from '@components/CopyTableToClipboard';
import { usePagination } from '@features/pagination/use-pagination';
import { PageSizeNames } from '@features/pagination/page-size-names';

const createSessionColumn = createColumnFactory<ISessionSummary>();
const defaultSortModel: GridSortModel = [
  {
    field: 'startMoment',
    sort: 'desc',
  },
];

const columns: GridColDef[] = [
  createSessionColumn('id', {
    headerName: 'Id',
    width: 50,
    renderCell: ({ row: session }) => (
      <Link
        key={session.id}
        component={NavLink}
        to={`${Routes.orderSummaries}/${session.customerId}/${session.orderId}`}
      >
        {session.id}
      </Link>
    ),
  }),
  createSessionColumn('startMoment', {
    headerName: 'Session Date Time',
    width: 190,
    renderCell: ({ row }) => formatDateTimeAndDuration(row.startMoment, row.duration),
  }),
  createSessionColumn('duration', { headerName: 'Duration', width: 80 }),
  createSessionColumn('isCancelled', {
    headerName: 'Cancelled',
    description: 'Cancelled within 24 hours',
    width: 90,
    renderCell: ({ row }) => <BooleanChip value={row.isCancelled} isInvertedColor />,
  }),
  createSessionColumn('tutorId', {
    headerName: 'Tutor',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.tutorId} optionKey="tutorOptions" />,
    optionKey: 'tutorOptions',
  }),
  createSessionColumn('userId', {
    headerName: 'Booked by',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.userId} optionKey="userOptions" />,
    optionKey: 'userOptions',
  }),
  createSessionColumn('createdAt', {
    headerName: 'Created',
    width: 90,
    renderCell: ({ row }) => formatDate(row.createdAt),
  }),
  createSessionColumn('funderInvoice', {
    headerName: 'Funder Invoice',
    width: 120,
  }),
  createSessionColumn('tutorInvoice', {
    headerName: 'Tutor Invoice',
    width: 120,
  }),
  createSessionColumn('isPaid', {
    headerName: 'Paid',
    width: 60,
    renderCell: ({ row }) => <BooleanChip value={row.isPaid} />,
  }),
  createSessionColumn('signedByStudent', {
    headerName: 'By Student',
    width: 80,
    renderCell: ({ row }) => <BooleanChip value={row.signedByStudent} />,
  }),
  createSessionColumn('signedByTutor', {
    headerName: 'By Tutor',
    width: 70,
    renderCell: ({ row }) => <BooleanChip value={row.signedByTutor} />,
  }),
  createSessionColumn('feedbackAvgSummary', { headerName: 'Feedback', width: 90 }),
];

export const SessionsPage: React.FC = () => {
  const { entries, totalEntries, paginationModel, onPaginationModelChange, isLoading } = usePagination(
    useFilteredSessions,
    PageSizeNames.Session,
  );

  return (
    <AppPaper>
      <Typography variant="h4" component="h1">
        Sessions
      </Typography>
      <Box sx={{ mt: 1 }}>
        <FilterSessionsForm />
      </Box>
      <AppDataGrid
        rows={entries}
        columns={columns}
        sortModel={defaultSortModel}
        loading={isLoading}
        sx={{ mt: 2 }}
        fullHeight
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        slots={{
          footer: () => (
            <Stack direction="row" sx={{ borderTop: '1px solid rgb(224, 224, 224)' }}>
              <CopyTableToClipboard rows={entries} columns={columns} />
              <GridFooter sx={{ flexGrow: 1, border: 'none' }} />
            </Stack>
          ),
        }}
      />
    </AppPaper>
  );
};
