import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { BulkUpdateInvoicesResponse } from './api';
import { useBulkUpdateInvoicesMutation } from '@api/api-slice';
import { useCallback } from 'react';

type HookResult = {
  handleBulkUpdateInvoices: (
    appointmentIds: number[],
    supplierInvoice: string,
  ) => Promise<BulkUpdateInvoicesResponse | void>;
} & RequestStatusFlags;

export function useBulkUpdateInvoices(): HookResult {
  const [mutation, flags] = useBulkUpdateInvoicesMutation();

  const handleBulkUpdateInvoices = useCallback(
    async (appointmentIds: number[], supplierInvoice: string): Promise<BulkUpdateInvoicesResponse | void> => {
      const result = await mutation({ appointmentIds, supplierInvoice }).unwrap();
      return result;
    },
    [mutation],
  );

  return {
    handleBulkUpdateInvoices,
    ...flags,
  };
}
