import { useCreateOrderContactRecordMutation } from '@api/api-slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useAppDispatch } from '@store/use-app-dispatch';
import { OrderContactRecordsActions } from './action-record.slice';
import { IProgressRecordRequest } from '@features/order/order-action-records/api';

export function useCreateOrderContactRecord(): {
  handleSaveContactRecord: (request: IProgressRecordRequest) => Promise<void>;
} & RequestStatusFlags {
  const [mutation, flags] = useCreateOrderContactRecordMutation();
  const dispatch = useAppDispatch();

  const handleSaveContactRecord = async (request: IProgressRecordRequest): Promise<void> => {
    const result = await mutation(request).unwrap();
    if (result) {
      dispatch(OrderContactRecordsActions.addRecord(result));
    }
  };

  return { handleSaveContactRecord, ...flags };
}
