import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useBulkInvoicingFilterTabs } from './filter-appointment-invoice-summaries/use-bulk-invoicing-filter-tabs';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { stringifyObjectFields } from '@utils/stringify-object-fields';

type Props = {
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
};

export const QuickFilters: React.FC<Props> = ({ onChange }) => {
  const [currentTab, setCurrentTab] = React.useState<number | null>(null);
  const { tabs, isLoading } = useBulkInvoicingFilterTabs();
  const [, setParams] = useSearchParams();

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setCurrentTab(newValue);
    const currentFilter = tabs.find((tab) => tab.id === newValue);
    if (currentFilter) {
      onChange(currentFilter.hasBulkSending);
      setParams(new URLSearchParams(stringifyObjectFields(filterEmptyObjectFields(currentFilter.fields))));
    }
  };

  useEffect(() => {
    if (tabs.length) {
      // eslint-disable-next-line prefer-destructuring
      const { id, hasBulkSending } = tabs[0];
      setCurrentTab(id);
      onChange(hasBulkSending);
    }
  }, [onChange, tabs]);

  if (isLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  return (
    <ToggleButtonGroup value={currentTab} onChange={handleChange} size="small" exclusive>
      {tabs.map((tab) => (
        <ToggleButton key={tab.id} value={tab.id}>
          {tab.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
