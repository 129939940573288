import React, { PropsWithChildren } from 'react';
import { Drawer, DrawerProps } from '@mui/material';

export type DrawerChildProps = {
  toggleDrawer: () => void;
  isDrawerOpen: boolean;
  width?: string;
};

type Props = PropsWithChildren<DrawerChildProps> & DrawerProps;

// TODO rework like AppModal
export const AppDrawer: React.FC<Props> = ({ isDrawerOpen, toggleDrawer, width = '72vw', children }) => {
  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={toggleDrawer}
      PaperProps={{ sx: { width, height: '100%', padding: 2 } }}
    >
      {children}
    </Drawer>
  );
};
