import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Stack } from '@mui/material';
import { ModalChildProps } from '@components/AppModal';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { useCreateOrderContactRecord } from '../order/order-action-records/use-create-order-contact-record';
import { IActionRecord } from '@features/action-records/action-record.interface';
import { RecordModelNames } from './record-model-name.type';
import { WayOptions, Ways } from './way.type';
import { RecordTypeOptions, RecordTypes } from './record-type.type';

type Props = { orderId: number } & ModalChildProps;

type Inputs = {
  way: IActionRecord['way'];
  record_type: IActionRecord['recordType'];
  details: string;
};

export const SaveContactRecordForm: React.FC<Props> = ({ orderId, onClose }) => {
  const defaultValues: Inputs = {
    way: null,
    record_type: null,
    details: '',
  };
  const { control, handleSubmit, formState } = useForm<Inputs>({ defaultValues });
  const { handleSaveContactRecord } = useCreateOrderContactRecord();

  const AvailableWayOptions = WayOptions.filter(({ value }) => value !== Ways.System);
  const AvailableRecordTypeOptions = RecordTypeOptions.filter(({ value }) => value !== RecordTypes.Action);

  const hasDirtyFields = Object.keys(formState.dirtyFields).length > 0;
  const isSaveEnabled = hasDirtyFields && !formState.isLoading && !formState.isSubmitting;

  const onSubmit = async (data: Inputs): Promise<void> => {
    await handleSaveContactRecord({ modelName: RecordModelNames.Order, id: orderId, request: data });
    onClose();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={2}>
        <ControlledAutocomplete
          control={control}
          name="way"
          label="Way"
          options={AvailableWayOptions}
          disableClearable
        />
        <ControlledAutocomplete
          control={control}
          name="record_type"
          label="Record Type"
          options={AvailableRecordTypeOptions}
          noOptionsText="First select Way"
          disableClearable
        />
        <ControlledTextField
          control={control}
          name="details"
          label="Details"
          rules={{ required: 'Details is required' }}
          multiline
          rows={2}
        />
        <Button variant="contained" size="large" type="submit" disabled={!isSaveEnabled}>
          Save Record
        </Button>
      </Stack>
    </form>
  );
};
