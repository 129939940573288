import React from 'react';
import { AppPaper } from '@components/AppPaper';
import { useQuote } from './use-quote';
import { Alert, CircularProgress } from '@mui/material';
import { QuoteDetails } from './QuoteDetails';
import { ProductTable } from '@features/product/product-table/ProductTable';
import { QuoteProgressRecords } from './QuoteProgressRecords';
import { QuoteAnnualSupportCharge } from './QuoteAnnualSupportCharge';

export const QuotePage: React.FC = () => {
  const { quote, isLoading } = useQuote();

  if (isLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  if (!quote) {
    return <Alert severity="warning">Error while requesting quote</Alert>;
  }

  return (
    <AppPaper>
      <QuoteDetails quote={quote} isOrderLink />
      <ProductTable products={quote.quoteProducts} quote={quote} isEditable={false} isCompact />
      <QuoteAnnualSupportCharge quote={quote} />
      <QuoteProgressRecords id={quote.id} />
    </AppPaper>
  );
};
