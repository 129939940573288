import { IOrder } from '@features/order/order.interface';
import { nullToString } from '@utils/null-to-string';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { IOrderSummary } from '../order-summary.interface';
import { emptyStringToNull } from '@utils/empty-string-to-null';

export type FormModel = {
  orderTypeId: IOrder['orderTypeId'][];
  orderStatusId: IOrder['orderStatusId'][];
  organisationId: IOrderSummary['organisationId'][];
  labels: IOrder['labels'][];
  query: string;
};

export type ApiModel = {
  orderTypeId: IOrder['orderTypeId'][];
  orderStatusId: IOrder['orderStatusId'][];
  organisationId: IOrderSummary['organisationId'][];
  labels: IOrder['labels'][];
  query: string | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderTypeId: { type: 'backend-label', optionKey: 'typeOptions', multiple: true, initial: [] },
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', multiple: true, initial: [] },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  query: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderTypeId: nullToString,
  orderStatusId: nullToString,
  organisationId: nullToString,
  labels: nullToString,
  query: emptyStringToNull,
});
