import React from 'react';
import { useAssessor } from './use-assessor';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { BackendLabelGroup } from '@features/backend-label/BackendLabelGroup';
import { getPartialByKeys } from '@utils/get-partial-by-keys';
import { AppDetails, DetailsConfig } from '@components/AppDetails';
import { EmptyCell } from '@utils/empty-cell';

export const AssessorDetail: React.FC = () => {
  const { assessor, isLoading } = useAssessor();

  if (isLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  if (!assessor) {
    return <Alert severity="warning">Assessor not found.</Alert>;
  }

  const assessorDetail = getPartialByKeys(assessor, [
    'firstName',
    'lastName',
    'email',
    'postcode',
    'organisationId',
    'disabilityTypeIds',
    'assessmentCentreTagIds',
    'weekAvgFeedbackValue',
    'monthAvgFeedbackValue',
    'avgFeedbackValue',
    'notes',
  ]);

  const config: DetailsConfig<typeof assessorDetail> = {
    firstName: { label: 'Name', render: (value) => value },
    lastName: { label: 'Last Name', render: (value) => value },
    email: { label: 'Email', render: (value) => value },
    postcode: { label: 'Postcode', render: (value) => value },
    organisationId: {
      label: 'Organisation',
      render: (value) => <BackendLabel value={value} optionKey="organisationOptions" />,
    },
    disabilityTypeIds: {
      label: 'Conditions',
      render: (value) => <BackendLabelGroup labels={value} optionKey="disabilityTypeOptions" />,
    },
    assessmentCentreTagIds: {
      label: 'Assessment Centre',
      render: (value) => <BackendLabelGroup labels={value} optionKey="assessmentCentreOptions" />,
    },
    weekAvgFeedbackValue: { label: 'Feedback Average Week', render: (value) => value?.toString() ?? EmptyCell },
    monthAvgFeedbackValue: { label: 'Feedback Average Month', render: (value) => value?.toString() ?? EmptyCell },
    avgFeedbackValue: { label: 'Feedback Average Total', render: (value) => value?.toString() ?? EmptyCell },
    notes: { label: 'Notes', render: (value) => value },
  };

  return (
    <>
      <Typography variant="h4" component="h1">
        Assessor Details
      </Typography>
      <AppDetails model={assessorDetail} config={config} />
    </>
  );
};
