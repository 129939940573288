import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { ToSnakeCase } from '@utils/model-to-snake-case';
import { validateActionRecords } from '@features/action-records/spec/validate-action-record.spec';
import { IActionRecord } from '@features/action-records/action-record.interface';
import { GetModelProgressRecordsQueryParams } from '@features/action-records/api';
import { RecordModelNames } from '@features/action-records/record-model-name.type';
import { IPaginable } from '@features/pagination/paginable.interface';
import { validatePagination } from '@features/pagination/spec/pagination.spec';

export const buildGetOrderContactRecords = appQueryFactory<
  GetModelProgressRecordsQueryParams,
  IPaginable<IActionRecord>
>({
  query: ({ modelName, id }) => ({
    url: `/contact_records/${modelName}/${id}`,
    method: 'GET',
  }),
  transformResponse(response: IPaginable<IActionRecord>, meta, request) {
    validateActionRecords(response.entries, `/contact_records/order/${request.id}`);
    validatePagination(response, `/contact_records/order/${request.id}`);
    return response;
  },
});

export interface IProgressRecordRequest {
  modelName: typeof RecordModelNames.Order;
  id: number;
  request: ToSnakeCase<Pick<IActionRecord, 'way' | 'recordType' | 'details'>>;
}
interface ICreateOrderContactRecordResponse {
  contactRecord: IActionRecord;
}

export const buildCreateOrderContactRecord = appMutationFactory<IProgressRecordRequest, IActionRecord>({
  query: ({ modelName, id, request }) => ({
    url: `/contact_records/${modelName}/${id}`,
    method: 'POST',
    data: request,
  }),
  transformResponse: (response: ICreateOrderContactRecordResponse): IActionRecord => response.contactRecord,
});
