import React from 'react';
import { AppPaper } from '@components/AppPaper';
import { AssessorDetail } from './AssessorDetail';
import { AssessorFeedback } from './AssessorFeedback';
import { AssessorWeekTimeSlots } from '@features/assessors/week-time-slots/AssessorWeekTimeSlots';
import { AssessorProgressRecords } from './AssessorProgressRecords';

export const AssessorPage: React.FC = () => {
  return (
    <AppPaper>
      <AssessorDetail />
      <AssessorWeekTimeSlots />
      <AssessorFeedback />
      <AssessorProgressRecords />
    </AppPaper>
  );
};
