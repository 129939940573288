import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Routes } from '@routes/routes';
import { RouteParams } from '@routes/route-params.type';
import { DashboardPage } from '@features/dashboard/DashboardPage';
import { DeliveryCalendar } from '@features/delivery-calendar/DeliveryCalendar';
import { FundingBodies } from '@features/FundingBodies';
import { Insurance } from '@features/Insurance';
import { ITF } from '@features/ITF';
import { KPIPage } from '@features/kpi/KPIPage';
import { Layout } from '@routes/Layout';
import { MyTasks } from '@features/MyTasks';
import { SessionsPage } from '@features/sessions/SessionsPage';
import { Notifications } from '@features/Notifications';
import { OrderSummaries } from '@features/order-summaries/OrderSummaries';
import { CustomerPage } from '@features/customer/CustomerPage';
import { Settings } from '@features/Settings';
import { Support } from '@features/Support';
import { Tutors } from '@features/tutors/Tutors';
import { Tutor } from '@features/tutors/Tutor';
import { AllocationsPage } from '@features/allocations/AllocationsPage';
import { Assessors } from '@features/assessors/Assessors';
import { Reset } from '@features/auth/reset/Reset';
import { RequireAuth } from '@features/auth/RequireAuth';
import { Login } from '@features/auth/login/Login';
import { Products } from '@features/product/Products';
import { ProductPage } from '@features/product/ProductPage';
import { QuoteListPage } from '@features/quote/QuoteListPage';
import { QuotePage } from '@features/quote/QuotePage';
import { AppointmentSummariesPage } from '@features/appointment-summaries/AppointmentSummariesPage';
import { AssessorPage } from '@features/assessors/AssessorPage';
import { AssessorUtilisationPage } from '@features/assessor-utilisation/AssessorUtilisationPage';
import { AssessmentCentres } from '@features/assessment-centres/AssessmentCentres';
import AssessmentCentrePage from '@features/assessment-centres/AssessmentCentrePage';
import { EquipmentInvoicing } from '@features/equipment-invoicing/EquipmentInvoicing';
import { HepPage } from '@features/hep/HepPage';
import { HepListPage } from '@features/hep/HepListPage';
import { BulkInvoicingPage } from '@features/bulk-invoicing/BulkInvoicingPage';

export const Router: React.FC = () => {
  const router = createBrowserRouter([
    { path: Routes.login, Component: RequireAuth(Login) },
    { path: Routes.reset, Component: RequireAuth(Reset) },
    {
      path: Routes.root,
      Component: RequireAuth(Layout),
      children: [
        { path: Routes.dashboard, Component: RequireAuth(DashboardPage) },
        { path: Routes.tasks, Component: RequireAuth(MyTasks) },
        { path: Routes.orderSummaries, Component: RequireAuth(OrderSummaries) },
        {
          path: `${Routes.orderSummaries}/:${RouteParams.customerId}/:${RouteParams.orderId}`,
          Component: RequireAuth(CustomerPage),
        },
        { path: `${Routes.orderSummaries}/:${RouteParams.customerId}`, Component: RequireAuth(CustomerPage) },
        { path: Routes.deliveryCalendar, Component: RequireAuth(DeliveryCalendar) },
        { path: Routes.allocations, Component: RequireAuth(AllocationsPage) },
        { path: Routes.sessions, Component: RequireAuth(SessionsPage) },
        { path: Routes.tutors, Component: RequireAuth(Tutors) },
        { path: `${Routes.tutors}/:${RouteParams.tutorId}`, Component: RequireAuth(Tutor) },
        { path: Routes.support, Component: RequireAuth(Support) },
        { path: Routes.insurance, Component: RequireAuth(Insurance) },
        { path: Routes.itf, Component: RequireAuth(ITF) },
        { path: Routes.products, Component: RequireAuth(Products) },
        { path: `${Routes.products}/:${RouteParams.productId}`, Component: RequireAuth(ProductPage) },
        { path: Routes.quotes, Component: RequireAuth(QuoteListPage) },
        { path: `${Routes.quotes}/:${RouteParams.quoteId}`, Component: RequireAuth(QuotePage) },
        { path: Routes.kpi, Component: RequireAuth(KPIPage) },
        { path: Routes.assessors, Component: RequireAuth(Assessors) },
        { path: `${Routes.assessors}/:${RouteParams.assessorId}`, Component: RequireAuth(AssessorPage) },
        { path: Routes.fundingBodies, Component: RequireAuth(FundingBodies) },
        { path: Routes.settings, Component: RequireAuth(Settings) },
        { path: Routes.notifications, Component: RequireAuth(Notifications) },
        { path: Routes.appointments, Component: RequireAuth(AppointmentSummariesPage) },
        { path: Routes.assessorUtilisation, Component: RequireAuth(AssessorUtilisationPage) },
        { path: Routes.assessmentCentres, Component: RequireAuth(AssessmentCentres) },
        {
          path: `${Routes.assessmentCentres}/:${RouteParams.assessmentCentreId}`,
          Component: RequireAuth(AssessmentCentrePage),
        },
        { path: `${Routes.myAvailability}`, Component: RequireAuth(AssessorPage) },
        { path: `${Routes.equipmentInvoicing}`, Component: RequireAuth(EquipmentInvoicing) },
        { path: Routes.heps, Component: RequireAuth(HepListPage) },
        { path: `${Routes.heps}/:${RouteParams.hepId}`, Component: RequireAuth(HepPage) },
        { path: `${Routes.bulkInvoicing}`, Component: RequireAuth(BulkInvoicingPage) },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
