import { useGetEquipmentInvoicesQuery } from '@api/api-slice';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { IPaginable } from '@features/pagination/paginable.interface';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { IEquipmentInvoice } from '../equipment-invoice.interface';

type HookResult = RequestStatusFlags & IPaginable<IEquipmentInvoice>;

export function useEquipmentInvoices(urlSearchParams: URLSearchParams, count: number, skip: number): HookResult {
  const params = deserializeURLSearchParams(urlSearchParams);
  return getPaginationResult(useGetEquipmentInvoicesQuery({ ...params, count, skip }));
}
