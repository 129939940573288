import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { useRole } from '@features/user/use-role';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { FormModel, sanitize, serialize } from './form-config';
import { stringifyObjectFields } from '@utils/stringify-object-fields';
import { BooleanOptions } from '@utils/boolean-options';
import { UserRoles } from '@features/user/user-role.type';
import { OrderClasses } from '@features/order/order-class.type';

// const FeedbackValues = [1, 2, 3, 4, 5].map((item) => ({ value: item, label: item.toString() }));

export const FilterAppointmentSummariesForm: React.FC = () => {
  const { isLoading, ...backendLabels } = useAppBackendLabels([
    'organisationOptions',
    'assessorOptions',
    'assessmentCentreOptions',
    'statusOptions',
  ]);
  const [params, setParams] = useSearchParams();
  const { control, handleSubmit, reset } = useForm<FormModel>({
    values: sanitize(deserializeURLSearchParams(params), backendLabels),
  });
  const role = useRole();
  const isAssessor = role === UserRoles.assessor;

  function onSubmit(data: FormModel): void {
    setParams(new URLSearchParams(stringifyObjectFields(filterEmptyObjectFields(serialize(data)))));
  }

  function onReset(): void {
    reset();
    setParams({});
  }

  const filteredStatuses = backendLabels.statusOptions.filter(
    (option) => option.orderClass === OrderClasses.NeedsAssessment,
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} columns={{ xs: 4, md: 8, lg: 12 }}>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledDatePicker control={control} name="startDate" label="Date From" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledDatePicker control={control} name="endDate" label="Date To" />
        </Grid>
        {!isAssessor && (
          <Grid item xs={4} md={4} lg={2}>
            <ControlledAutocomplete
              control={control}
              name="organisationId"
              label="Organisation"
              options={backendLabels.organisationOptions}
              isLoading={isLoading}
              multiple
            />
          </Grid>
        )}
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="assessorId"
            label="Assessor"
            options={backendLabels.assessorOptions}
            isLoading={isLoading}
            multiple
            isNullAvailable
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="assessmentCentreId"
            label="Assessment Centre"
            options={backendLabels.assessmentCentreOptions}
            isLoading={isLoading}
            isNullAvailable
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="orderStatus"
            label="Order Status"
            options={filteredStatuses}
            isLoading={isLoading}
            isStatus
          />
        </Grid>
        {!isAssessor && (
          <Grid item xs={4} md={4} lg={2}>
            <ControlledTextField control={control} name="funderInvoice" label="Funder Body Invoice" />
          </Grid>
        )}
        {!isAssessor && (
          <Grid item xs={4} md={4} lg={2}>
            <ControlledTextField control={control} name="supplierInvoice" label="Supplier Body Invoice" />
          </Grid>
        )}
        {!isAssessor && (
          <Grid item xs={4} md={4} lg={2}>
            <ControlledAutocomplete control={control} name="isPaid" label="Is Paid" options={BooleanOptions} />
          </Grid>
        )}
        <Grid item xs={4} md={4} lg={2}>
          <ControlledTextField control={control} name="query" label="Customer Name or CRN" />
        </Grid>
        <Grid item xs={4} md={4} lg={1}>
          <ControlledTextField control={control} name="appointmentId" label="Id" />
        </Grid>
        <Grid item xs={4} md={4} lg={1}>
          <Button type="reset" variant="outlined" size="medium" onClick={onReset} fullWidth>
            Reset
          </Button>
        </Grid>
        <Grid item xs={4} md={8} lg={2}>
          <Button type="submit" variant="contained" fullWidth>
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
