import React from 'react';
import { useOrderTypeStatusSummaries } from './use-order-type-status-summaries';
import { Grid } from '@mui/material';
import { StatusTable } from './StatusTable';
import { OrderTypeTable } from './OrderTypeTable';

export const DashboardPage: React.FC = () => {
  const { summaries, isLoading } = useOrderTypeStatusSummaries();
  return (
    <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
      <Grid item xs={4} md={4} lg={6}>
        <OrderTypeTable />
      </Grid>
      {summaries.map((summary) => (
        <Grid item xs={4} md={4} lg={6} key={`status-${summary.type}`}>
          <StatusTable isLoading={isLoading} {...summary} />
        </Grid>
      ))}
    </Grid>
  );
};
