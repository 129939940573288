import React from 'react';
import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAppSelector } from '@store/use-app-selector';
import { useAppDispatch } from '@store/use-app-dispatch';
import { UserActions } from '@features/user/user.slice';

export const User: React.FunctionComponent = () => {
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();

  if (!user) {
    return null;
  }

  const { avatar, first_name, last_name, role } = user;

  const userName = `${first_name} ${last_name}`;
  const fallback = `${first_name[0]}${last_name[0]}`.toUpperCase();

  function handleLogout(): void {
    dispatch(UserActions.logOut());
  }

  return (
    <Stack direction="row" alignItems="center" sx={{ padding: 2, borderBottom: 1, borderColor: 'divider' }}>
      <Box>
        <Avatar alt={userName} src={avatar}>
          {fallback}
        </Avatar>
      </Box>
      <Box sx={{ flexGrow: 1, paddingLeft: 1 }}>
        <Typography>{userName}</Typography>
        <Typography>{role}</Typography>
      </Box>
      <Box>
        <IconButton onClick={handleLogout}>
          <LogoutIcon />
        </IconButton>
      </Box>
    </Stack>
  );
};
