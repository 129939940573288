import { useGetAppointmentFilterTabsQuery } from '@api/api-slice';
import { IBulkInvoicingFilterTab } from './bulk-invoicing-filter-tab.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';

type HookResult = RequestStatusFlags & { tabs: IBulkInvoicingFilterTab[] };

export function useBulkInvoicingFilterTabs(): HookResult {
  const { data, ...flags } = useGetAppointmentFilterTabsQuery();
  return { tabs: data?.tabs ?? [], ...flags };
}
