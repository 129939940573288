import React, { useContext, useMemo } from 'react';
import { useAppSelector } from '@store/use-app-selector';
import { Button, ButtonProps, IconButton, Link } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import { baseUrl } from '@environment/config';
import { PreviewPDFContext } from '@context/PreviewPDF';

type TextProps = {
  text: string;
  isLink?: boolean;
  Icon?: never;
};

type IconProps = {
  text?: never;
  isLink?: never;
  Icon: SvgIconComponent;
};

export type OpenFileProps = (TextProps | IconProps) &
  ButtonProps & {
    path: string;
    isPreview?: boolean;
  };

/**
 * Component emulating link to a file with token in query parameter from the store.
 * Hide token parameter from showing in the browser.
 *
 * Should either accept `Icon` or `text` prop.
 *
 * If `Icon` passed, it renders MUI IconButton. Otherwise `text` is required and renders regular text button.
 * */
export const OpenFile: React.FC<OpenFileProps> = ({
  path,
  text,
  isPreview = false,
  isLink = false,
  Icon,
  ...props
}) => {
  const token = useAppSelector((state) => state.user.token);
  const { openPDF } = useContext(PreviewPDFContext);

  const link = useMemo(() => {
    if (token) {
      const url = new URL(`${baseUrl}${path}`);
      url.searchParams.set('token', encodeURIComponent(token));
      if (isPreview) {
        url.searchParams.set('preview', '');
      }
      return url.toString();
    }
    return null;
  }, [token, path, isPreview]);

  const handleClick = (): void => {
    if (link) {
      if (isPreview) {
        openPDF(link);
      } else {
        window.location.href = link;
      }
    } else {
      // TODO replace with logout
      console.warn('There is no token, replace with logout');
    }
  };

  if (Icon) {
    return (
      <IconButton {...props} onClick={handleClick}>
        <Icon />
      </IconButton>
    );
  }

  if (isLink) {
    return (
      <Link onClick={handleClick} sx={{ cursor: 'pointer' }}>
        {text}
      </Link>
    );
  }

  return (
    <Button {...props} onClick={handleClick}>
      {text}
    </Button>
  );
};
