import { appQueryFactory } from '@api/api-slice';
import { ISession } from './session.interface';
import { validateSessions } from './spec/validate-sessions.spec';

type Response = {
  nmhSessions: ISession[];
};

export const buildGetAllocationSessions = appQueryFactory<number, ISession[]>({
  query: (allocationId) => ({
    url: `/nmh_allocations/${allocationId}/nmh_sessions`,
    method: 'GET',
  }),
  transformResponse(response: Response) {
    validateSessions(response.nmhSessions, '/nmh_allocations/:orderId/nmh_sessions');
    return response.nmhSessions;
  },
});
