// This is an autogenerated file during deploy.sh
// Please do not change it manually
// We need common js module for using with webpack
// So here we use js file with ts typings
module.exports = {
  baseUrl: 'https://sandbox2-api.assistivedsa.com',
  title: 'Sandbox2 Assistive DSA CRM',
  color: '#c896de',
  isWarning: true,
};
