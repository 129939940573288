import React from 'react';
import { AppPaper } from '@components/AppPaper';
import { Order } from '@features/order/Order';
import { CustomerDetails } from './CustomerDetails';

export const CustomerPage: React.FC = () => {
  return (
    <AppPaper sx={{ backgroundColor: 'var(--color-gray)' }}>
      <CustomerDetails />
      <Order />
    </AppPaper>
  );
};
