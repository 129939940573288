import React, { useState } from 'react';
import { Button, Popover, Stack } from '@mui/material';
import { FileDetails } from '@models/file-details.type';
import { OpenFile, OpenFileProps } from './OpenFile';

type Props = {
  fileDetails: string | FileDetails[];
  text: string;
} & Omit<OpenFileProps, 'path' | 'text'>;

export const OpenMultipleFiles: React.FC<Props> = ({ fileDetails, ...openFileProps }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  if (typeof fileDetails === 'string') {
    return <OpenFile {...openFileProps} path={fileDetails} Icon={undefined} />;
  }

  return (
    <>
      <Button fullWidth variant="outlined" size="medium" onClick={handleClick}>
        {openFileProps.text}
      </Button>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack direction="column">
          {fileDetails.map(({ name, path }) => (
            <OpenFile {...openFileProps} key={path} path={path} text={name} Icon={undefined} />
          ))}
        </Stack>
      </Popover>
    </>
  );
};
