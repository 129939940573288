import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { AppModal } from '@components/AppModal';
import { useSensitivePersonalInformationPresence } from './use-sensitive-personal-information-presence';
import { useRequestSensitivePersonalInformation } from './use-request-sensitive-personal-information';
import { IOrderNeedsAssessment } from '../order-needs-assessment.interface';
import { RequestReason } from './RequestReason';
import { useToggle } from '@hooks/use-toggle';
import { AppDrawer } from '@components/AppDrawer';
import { SensitivePersonalInformationDrawer } from './SensitivePersonalInformationDrawer';

type Props = {
  order: IOrderNeedsAssessment;
};

export const SensitivePersonalInformation: React.FC<Props> = ({ order }) => {
  const [isLocked, setIsLocked] = useState(false);
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle();
  const [reason, setReason] = useState('');
  const presence = useSensitivePersonalInformationPresence(order.id);
  const {
    hasConditions,
    hasDisabilityTypes,
    hasDsa1,
    hasMedicalEvidence,
    hasPreAssessmentForm,
    hasDateOfBirth,
    hasNarFile,
  } = presence;
  const { requestHandler, spi, setSpi, isLoading } = useRequestSensitivePersonalInformation();

  async function handleRequest(): Promise<void> {
    try {
      await requestHandler(order.id, reason);
      toggleIsModalOpen();
      toggleIsDrawerOpen();
      setIsLocked(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleClick = (): unknown => (isLocked ? toggleIsModalOpen() : toggleIsDrawerOpen());

  const isRequestButtonDisabled = reason === '' || isLoading;

  const isSensitivePersonalInformationInitiallyLocked =
    hasConditions ||
    hasDisabilityTypes ||
    hasDsa1 ||
    hasMedicalEvidence ||
    hasPreAssessmentForm ||
    hasDateOfBirth ||
    hasNarFile;

  useEffect(() => {
    setIsLocked(isSensitivePersonalInformationInitiallyLocked);
  }, [isSensitivePersonalInformationInitiallyLocked]);

  return (
    <>
      <Button onClick={handleClick} variant="outlined" fullWidth endIcon={isLocked ? <LockIcon /> : <LockOpenIcon />}>
        Open Sensitive Personal Information
      </Button>

      <AppDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleIsDrawerOpen}>
        <SensitivePersonalInformationDrawer
          {...presence}
          spi={spi}
          setSpi={setSpi}
          isLocked={isLocked}
          orderId={order.id}
        />
      </AppDrawer>

      <AppModal open={isModalOpen} onClose={toggleIsModalOpen} title="Please Fill Request Reason">
        <Box>
          <RequestReason onChange={setReason} />
          <Button
            fullWidth
            variant="contained"
            onClick={handleRequest}
            disabled={isRequestButtonDisabled}
            sx={{ mt: 2 }}
          >
            Request Information
          </Button>
        </Box>
      </AppModal>
    </>
  );
};
