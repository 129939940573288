import { IActionRecord } from '@features/action-records/action-record.interface';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

const orderContactRecordsAdapter = createEntityAdapter<IActionRecord>({
  selectId: (entry) => entry.id,
});

const orderContactRecordsSlice = createSlice({
  name: 'OrderContactRecords',
  initialState: orderContactRecordsAdapter.getInitialState(),
  reducers: {
    setRecords: orderContactRecordsAdapter.setAll,
    addRecord: orderContactRecordsAdapter.addOne,
  },
});

export const OrderContactRecordsActions = orderContactRecordsSlice.actions;
export const orderContactRecordsReducer = orderContactRecordsSlice.reducer;

const { selectAll } = orderContactRecordsAdapter.getSelectors((state: RootState) => state.orderContactRecords);

export const selectAllRecords = selectAll;
