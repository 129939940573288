import * as Icons from '@mui/icons-material';
import { RouteType, Routes } from '@routes/routes';
import { UserRoles } from '@features/user/user-role.type';
import { CrmAccessibleRoleType } from './default-routes-by-role';

type ProtectedMenuLink = {
  name: string;
  path: RouteType;
  icon: Icons.SvgIconComponent;
  divider: boolean;
  roles: Array<CrmAccessibleRoleType>;
};

export const protectedMenuLinks: ProtectedMenuLink[] = [
  {
    name: 'Dashboard',
    path: Routes.dashboard,
    icon: Icons.Dashboard,
    divider: false,
    roles: [UserRoles.operator, UserRoles.qualityAssurance],
  },
  {
    name: 'My Tasks',
    path: Routes.tasks,
    icon: Icons.Assignment,
    divider: true,
    roles: [],
  },
  {
    name: 'Orders',
    path: Routes.orderSummaries,
    icon: Icons.LocalShipping,
    divider: false,
    roles: [UserRoles.operator, UserRoles.assessor, UserRoles.qualityAssurance],
  },
  {
    name: 'Delivery Calendar',
    path: Routes.deliveryCalendar,
    icon: Icons.CalendarToday,
    divider: false,
    roles: [UserRoles.operator],
  },
  {
    name: 'Equipment Invoicing',
    path: Routes.equipmentInvoicing,
    icon: Icons.ImportantDevices,
    divider: false,
    roles: [UserRoles.operator],
  },
  {
    name: 'Allocations',
    path: Routes.allocations,
    icon: Icons.School,
    divider: false,
    roles: [UserRoles.operator],
  },
  {
    name: 'Sessions',
    path: Routes.sessions,
    icon: Icons.Alarm,
    divider: false,
    roles: [UserRoles.operator],
  },
  {
    name: 'Tutors',
    path: Routes.tutors,
    icon: Icons.AccountCircle,
    divider: true,
    roles: [UserRoles.operator],
  },
  {
    name: 'Bulk Invoicing',
    path: Routes.bulkInvoicing,
    icon: Icons.ViewList,
    divider: false,
    roles: [UserRoles.operator],
  },
  {
    name: 'Support',
    path: Routes.support,
    icon: Icons.ContactSupport,
    divider: false,
    roles: [],
  },
  {
    name: 'Insurance',
    path: Routes.insurance,
    icon: Icons.FlightLand,
    divider: false,
    roles: [],
  },
  {
    name: 'ITF',
    path: Routes.itf,
    icon: Icons.ProductionQuantityLimits,
    divider: true,
    roles: [],
  },
  {
    name: 'Products',
    path: Routes.products,
    icon: Icons.Equalizer,
    divider: false,
    roles: [UserRoles.operator, UserRoles.assessor],
  },
  {
    name: 'Quotes',
    path: Routes.quotes,
    icon: Icons.PieChart,
    divider: false,
    roles: [UserRoles.operator],
  },
  {
    name: 'KPIs',
    path: Routes.kpi,
    icon: Icons.PieChart,
    divider: false,
    roles: [UserRoles.operator],
  },
  {
    name: 'Assessors',
    path: Routes.assessors,
    icon: Icons.GroupWork,
    divider: false,
    roles: [UserRoles.operator],
  },
  {
    name: 'Funding Bodies',
    path: Routes.fundingBodies,
    icon: Icons.LocationCity,
    divider: true,
    roles: [],
  },
  {
    name: 'Main Settings',
    path: Routes.settings,
    icon: Icons.Settings,
    divider: false,
    roles: [],
  },
  {
    name: 'Notifications',
    path: Routes.notifications,
    icon: Icons.Notifications,
    divider: false,
    roles: [],
  },
  {
    name: 'Appointments',
    path: Routes.appointments,
    icon: Icons.School,
    divider: false,
    roles: [UserRoles.operator, UserRoles.assessor],
  },
  {
    name: 'Assessor Utilisation',
    path: Routes.assessorUtilisation,
    icon: Icons.PieChart,
    divider: false,
    roles: [UserRoles.operator],
  },
  {
    name: 'Assessment Centres',
    path: Routes.assessmentCentres,
    icon: Icons.School,
    divider: false,
    roles: [UserRoles.operator],
  },
  {
    name: 'My Availability',
    path: Routes.myAvailability,
    icon: Icons.CalendarMonth,
    divider: false,
    roles: [UserRoles.assessor],
  },
  {
    name: 'Hep',
    path: Routes.heps,
    icon: Icons.PieChart,
    divider: false,
    roles: [UserRoles.operator, UserRoles.assessor],
  },
];
