import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import { GridPagination, GridPaginationModel, GridRowSelectionModel, GridSelectedRowCount } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppPaper } from '@components/AppPaper';
import { renderMoney } from '@utils/render-money';
import { FilterAppointmentInvoiceSummaries } from './filter-appointment-invoice-summaries/FilterAppointmentInvoiceSummaries';
import { useFilteredAppointmentInvoiceSummaries } from './filter-appointment-invoice-summaries/use-filtered-appointment-invoice-summaries';
import { QuickFilters } from './QuickFilters';
import { useToggle } from '@hooks/use-toggle';
import { AppDrawer } from '@components/AppDrawer';
import { columns } from './columns';
import { AddSupplierInvoice } from './add-supplier-invoice/AddSupplierInvoice';

export const BulkInvoicingPage: React.FC = () => {
  const [params] = useSearchParams();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 15,
  });
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);

  const count = paginationModel.pageSize;
  const skip = paginationModel.page * count;
  const { entries, pagination, isLoading } = useFilteredAppointmentInvoiceSummaries(params, count, skip);
  const [totalEntries, setTotalEntries] = useState(pagination.totalEntries);
  const [isQuickFilterActionAllowed, setIsQuickFilterActionAllowed] = React.useState(true); //false

  useEffect(() => {
    setTotalEntries(pagination.totalEntries);
  }, [pagination.totalEntries]);

  const [isModalOpen, toggleIsModalOpen] = useToggle(false);

  const handleAction = async (): Promise<void> => {
    toggleIsModalOpen();
  };

  const isButtonDisabled = !isQuickFilterActionAllowed || selection.length === 0;

  const selectedRows = entries.filter((item) => selection.includes(item.id));
  const totalGrossCost = selectedRows.reduce((a, c) => a + c.exVat, 0);

  return (
    <AppPaper>
      <Stack direction="row" alignItems="flex-start" sx={{ mb: 1 }}>
        <Typography variant="h4" component="h1" sx={{ mr: 2 }}>
          Finance — Assessment Appointments
        </Typography>
        <QuickFilters onChange={setIsQuickFilterActionAllowed} />
      </Stack>

      <FilterAppointmentInvoiceSummaries />
      <AppDataGrid
        rows={entries}
        columns={columns}
        loading={isLoading}
        sx={{ mt: 1 }}
        checkboxSelection={isQuickFilterActionAllowed}
        onRowSelectionModelChange={setSelection}
        rowSelectionModel={selection}
        disableRowSelectionOnClick={!isQuickFilterActionAllowed}
        fullHeight
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slots={{
          footer: () => (
            <Stack direction="row" sx={{ borderTop: '1px solid rgb(224, 224, 224)', p: 1 }}>
              <Button onClick={handleAction} disabled={isButtonDisabled}>
                Generate Invoices
              </Button>
              {selection.length > 0 && (
                <>
                  <GridSelectedRowCount selectedRowCount={selection.length} />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>{`${renderMoney(totalGrossCost)} Total`}</Box>
                </>
              )}
              <GridPagination sx={{ ml: 'auto' }} />
            </Stack>
          ),
        }}
      />
      <AppDrawer isDrawerOpen={isModalOpen} toggleDrawer={toggleIsModalOpen} width="92vw">
        <AddSupplierInvoice rows={selectedRows} />
      </AppDrawer>
    </AppPaper>
  );
};
