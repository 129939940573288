import React from 'react';
import { Alert, Box, CircularProgress } from '@mui/material';
import { IOrder } from './order.interface';
import { IOrderNew } from './new/order-new.interface';
import { OrderNMH } from './nmh/OrderNMH';
import { OrderTechnical } from './technical/OrderTechnical';
import { OrderNew } from './new/OrderNew';
import { OrderActionRecords } from './order-action-records/OrderActionRecords';
import { EmailTemplates } from '@features/customer/email-templates/EmailTemplates';
import { OrderClasses } from './order-class.type';
import { OrderNeedsAssessment } from './needs-assessment/OrderNeedsAssessment';
import { OrderRepairAndReplace } from './repair-and-replace/OrderRepairAndReplace';
import { RestrictRole } from '@features/auth/RestrictRole';
import { UserRoles } from '@features/user/user-role.type';
import { useOrder } from './use-order';
import { OrderAnnualSupportCharge } from './annual-support-charge/OrderAnnualSupportCharge';
import { OrderUnsupportedCustomer } from './unsupported-customer/OrderUnsupportedCustomer';

type Props = {
  order: IOrder;
};

const OrderBase: React.FC<Props> = ({ order }) => {
  switch (order.orderClass) {
    case OrderClasses.Nmh:
      return <OrderNMH order={order} />;
    case OrderClasses.Technical:
      return <OrderTechnical order={order} />;
    case OrderClasses.NeedsAssessment:
      return <OrderNeedsAssessment order={order} />;
    case OrderClasses.RepairAndReplace:
      return <OrderRepairAndReplace order={order} />;
    case OrderClasses.ErgonomicAssessment:
      return <OrderNew order={order as unknown as IOrderNew} onClose={(): void => void 0} />;
    case OrderClasses.AnnualSupportCharge:
      return <OrderAnnualSupportCharge order={order} />;
    case OrderClasses.UnsupportedCustomer:
      return <OrderUnsupportedCustomer order={order} />;
    default:
      return <OrderNew order={order} onClose={(): void => void 0} />;
  }
};

export const Order: React.FC = () => {
  const { order, isFetching } = useOrder();

  // TODO_REF use isFetching anywhere
  if (isFetching) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  if (!order) {
    return <Alert severity="warning">Order not found</Alert>;
  }

  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      <OrderBase order={order} />
      <OrderActionRecords orderId={order.id} />
      <RestrictRole roles={[UserRoles.assessor, UserRoles.qualityAssurance]}>
        <EmailTemplates customerId={order.customerId} orderId={order.id} orderTypeId={order.orderTypeId} />
      </RestrictRole>
    </Box>
  );
};
