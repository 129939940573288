import React from 'react';
import { NavLink } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { Box, Link, Stack, Typography } from '@mui/material';
import { AppPaper } from '@components/AppPaper';
import { useAssessors } from './use-assessors';
import { createColumnFactory } from '@utils/create-column.factory';
import { Routes } from '@routes/routes';
import { BackendLabelGroup } from '@features/backend-label/BackendLabelGroup';
import { IAssessor } from './assessor.interface';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { FilterAssessors } from './filter-assessors-form/FilterAssessors';
import { usePagination } from '@features/pagination/use-pagination';
import { PageSizeNames } from '@features/pagination/page-size-names';

const createAssessorColumn = createColumnFactory<IAssessor>();

const columns: GridColDef[] = [
  createAssessorColumn('id', {
    headerName: 'ID',
    width: 45,
    renderCell: ({ row }) => (
      <Link key={row.id} component={NavLink} to={`${Routes.assessors}/${row.id}`}>
        <span>{row.id}</span>
      </Link>
    ),
  }),
  createAssessorColumn('firstName', {
    // TODO make fullname on the frontend model
    headerName: 'Name',
    flex: 1,
    renderCell: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  }),
  createAssessorColumn('organisationId', {
    headerName: 'Organisation',
    width: 150,
    renderCell: (params) => <BackendLabel value={params.row.organisationId} optionKey="organisationOptions" />,
  }),
  createAssessorColumn('disabilityTypeIds', {
    headerName: 'Conditions',
    flex: 1,
    renderCell: (params) => (
      <BackendLabelGroup labels={params.row.disabilityTypeIds} optionKey="disabilityTypeOptions" />
    ),
  }),
  createAssessorColumn('postcode', {
    headerName: 'Postcode',
    width: 80,
  }),
  createAssessorColumn('assessmentCentreTagIds', {
    headerName: 'Assessment Centre',
    flex: 1,
    renderCell: (params) => (
      <BackendLabelGroup labels={params.row.assessmentCentreTagIds} optionKey="assessmentCentreOptions" />
    ),
  }),
  createAssessorColumn('modesOfAssessment', {
    headerName: 'Mode',
    width: 120,
  }),
  createAssessorColumn('notes', {
    headerName: 'About',
    flex: 1,
  }),
];

export const Assessors: React.FC = () => {
  const { entries, totalEntries, paginationModel, onPaginationModelChange, isLoading } = usePagination(
    useAssessors,
    PageSizeNames.Assessor,
  );

  return (
    <AppPaper>
      <Stack direction="row" alignItems="center">
        <Typography variant="h4" component="h1">
          Assessors
        </Typography>
        <Box sx={{ flexGrow: 1, ml: 1 }}>
          <FilterAssessors />
        </Box>
      </Stack>
      <AppDataGrid
        rows={entries}
        columns={columns}
        loading={isLoading}
        sx={{ mt: 1 }}
        fullHeight
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
      />
    </AppPaper>
  );
};
