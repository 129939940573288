import { Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { createColumnFactory } from '@utils/create-column.factory';
import { useTutors } from './use-tutors';
import { GridColDef } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppPaper } from '@components/AppPaper';
import { BackendLabelGroup } from '@features/backend-label/BackendLabelGroup';
import { ITutor } from '@features/tutors/tutor.interface';
import { Routes } from '@routes/routes';
import { NavLink } from 'react-router-dom';
import { BooleanChip } from '@utils/render-boolean';

const createTutorColumn = createColumnFactory<ITutor>();

const columns: GridColDef[] = [
  createTutorColumn('id', {
    headerName: 'ID',
    width: 45,
    renderCell: ({ row }) => (
      <Link key={row.id} component={NavLink} to={`${Routes.tutors}/${row.id}`}>
        {row.id}
      </Link>
    ),
  }),
  createTutorColumn('firstName', {
    // TODO make fullname on the frontend model
    headerName: 'Name',
    flex: 1,
    renderCell: ({ row }) => `${row.firstName || ''} ${row.lastName || ''}`,
  }),
  createTutorColumn('isActive', {
    headerName: 'Active',
    width: 75,
    renderCell: ({ row }) => <BooleanChip value={row.isActive} />,
  }),
  createTutorColumn('employmentType', {
    headerName: 'Status',
    width: 100,
  }),
  createTutorColumn('qualification', {
    headerName: 'Support Type',
    flex: 2,
    renderCell: ({ row }) => <BackendLabelGroup labels={row.qualification} optionKey="qualificationOptions" />,
  }),
  createTutorColumn('activeSupportAssigned', {
    headerName: 'Active Support Assigned',
    width: 100,
  }),
  createTutorColumn('feedbackLast', {
    headerName: 'Last Feedback',
    width: 100,
  }),
  createTutorColumn('feedbackAverageWeek', {
    headerName: 'Week Average Feedback',
    width: 100,
  }),
  createTutorColumn('feedbackAverageMonth', {
    headerName: 'Month Feedback',
    width: 100,
  }),
  createTutorColumn('feedbackAverageTotal', {
    headerName: 'Total Average Feedback',
    width: 100,
  }),
  createTutorColumn('hourlyRate', {
    headerName: 'Hourly Rate',
    headerAlign: 'right',
    align: 'right',
    width: 100,
  }),
];

export const Tutors: React.FC = () => {
  const { tutors, isLoading } = useTutors();
  return (
    <AppPaper>
      <Stack direction="row" alignItems="center">
        <Typography variant="h4" component="h1" sx={{ flexGrow: 1 }}>
          Tutors
        </Typography>
      </Stack>
      <AppDataGrid rows={tutors} columns={columns} loading={isLoading} fullHeight />
    </AppPaper>
  );
};
