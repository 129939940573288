import { appMutationFactory } from '@api/api-slice';
import { ToSnakeCase, modelToSnakeCase } from '@utils/model-to-snake-case';

export type Request = {
  appointmentIds: number[];
  supplierInvoice: string;
};

export type Payload = ToSnakeCase<Request>;

function createPayload(request: Request): Payload {
  return modelToSnakeCase(request);
}

export type BulkUpdateInvoicesResponse = {
  acceptedAppointmentsIds: number[];
  rejectedAppointmentsIds: number[];
};
export const buildBulkUpdateInvoices = appMutationFactory<Request, BulkUpdateInvoicesResponse | void>({
  query: (payload) => ({
    url: `/needs_assessment_appointments/bulk-supplier-invoice-update`,
    method: 'POST',
    data: createPayload(payload),
  }),
});
