import React from 'react';
import { StyledTable } from '@components/StyledTable';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { IQuoteDetail } from '@features/quote/quote.interface';
import { Box, Button } from '@mui/material';
import { formatDate } from '@utils/dates/format-date';
import { AppDrawer } from '@components/AppDrawer';
import { useToggle } from '@hooks/use-toggle';
import { IProductSummary } from '@features/product/product.interface';
import { createTotalRow } from '@features/product/product-table/create-total-row';

type Props = {
  products: IProductSummary[];
  quote: IQuoteDetail;
};

const columns: Array<{ field: keyof IProductSummary; headerName: string }> = [
  { field: 'productName', headerName: 'Item' },
  { field: 'quantity', headerName: 'Quantity' },
  { field: 'productCode', headerName: 'Product Code' },
  { field: 'amountExVat', headerName: 'Ex VAT' },
  { field: 'amountInclVat', headerName: 'Inc VAT' },
];

export const PrintQuote: React.FC<Props> = ({ products, quote }) => {
  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle();

  return (
    <>
      <Button onClick={toggleIsDrawerOpen} variant="outlined" fullWidth sx={{ mt: 2 }}>
        Print Quote
      </Button>
      <AppDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleIsDrawerOpen}>
        <div style={{ backgroundColor: 'white' }}>
          <div>
            <strong>Quote: </strong> {quote.quoteInternalId}
          </div>
          <div>
            <div style={{ marginTop: '10px' }}>
              <strong>CRN: </strong> {quote.crn}
            </div>
            <div style={{ marginTop: '10px' }}>
              <strong>Assessment Centre: </strong>
              <BackendLabel value={quote.assessmentCentreId} optionKey="assessmentCentreOptions" isPlainText />
            </div>
            <div style={{ marginTop: '10px' }}>
              <strong>Support Period: </strong>
              <BackendLabel value={quote.supportPeriod} optionKey="quoteSupportPeriodOptions" isPlainText />
            </div>
            <div style={{ marginTop: '10px' }}>
              <strong>Date Generated: </strong> {formatDate(quote.createdAt)}
            </div>
          </div>
        </div>
        <Box sx={{ mt: 1 }}>
          <StyledTable columns={columns} rows={products.concat(createTotalRow(quote))} />
        </Box>
      </AppDrawer>
    </>
  );
};
