import React from 'react';
import { TutorDetails } from './TutorDetails';
import { TutorFeedback } from './TutorFeedback';
import { AppPaper } from '@components/AppPaper';

export const Tutor: React.FC = () => {
  return (
    <AppPaper>
      <TutorDetails />
      <TutorFeedback />
    </AppPaper>
  );
};
