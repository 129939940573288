import React, { useState } from 'react';
import { stringify } from 'qs';
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment';
import { OpenFile } from '@components/inputs/OpenFile';
import { WhyDisabled } from '@components/WhyDisabled';
import { DateFormat } from '@utils/dates/formats';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { QSOptions } from '@utils/qs-options';
import { OrderClasses } from '@features/order/order-class.type';

type Props = {
  url: string;
  number: number;
  title: string;
  label: string;
  hasFundingBody?: true;
  hasTechOrderType?: true;
  hasEquipmentDeliveryDate?: true;
};

const MaxDifferenceInDays = 90;

export const KPI: React.FC<Props> = ({
  url,
  number,
  title,
  label,
  hasFundingBody,
  hasTechOrderType,
  hasEquipmentDeliveryDate,
}) => {
  const [from, setFrom] = useState<Moment | null>(moment().add(-1, 'month'));
  const [to, setTo] = useState<Moment | null>(moment());
  const [equipmentDeliveryDate, setEquipmentDeliveryDate] = useState<Moment | null>(moment());
  const [orderTypeId, setOrderTypeId] = useState<Array<number | null>>([]);
  const [fundingBodyId, setFundingBodyId] = useState<Array<number | null>>([]);
  const { orderTypeNameOptions, fundingBodyOptions } = useAppBackendLabels([
    'orderTypeNameOptions',
    'fundingBodyOptions',
  ]);
  const techTypeOptions = orderTypeNameOptions.filter((option) => option.orderClass === OrderClasses.Technical);

  const differenceInDays = from && to ? to.diff(from, 'days') : 0;
  const isValidRange = 0 < differenceInDays && differenceInDays <= MaxDifferenceInDays;

  const params = stringify(
    {
      from: from?.format('YYYY-MM-DD'),
      to: to?.format('YYYY-MM-DD'),
      orderTypeId,
      fundingBodyId,
    },
    QSOptions,
  );

  const path = `${url}&${params}`;

  let columnWidthLg = 4;
  if (hasFundingBody && hasTechOrderType) {
    columnWidthLg = 3;
  }
  if (hasFundingBody && hasEquipmentDeliveryDate) {
    columnWidthLg = 3;
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h5" component="h1">
        KPI {number} {title}
      </Typography>
      <Typography variant="body1" component="p">
        {label}
      </Typography>
      <Grid container spacing={1} columns={{ xs: 4, md: 8, lg: 12 }} sx={{ mt: 0, mb: 1 }}>
        <Grid item xs={4} md={4} lg={columnWidthLg}>
          <DatePicker
            label="From"
            value={from}
            onChange={(newValue): unknown => setFrom(newValue)}
            format={DateFormat}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={columnWidthLg}>
          <DatePicker
            label="To"
            value={to}
            onChange={(newValue): unknown => setTo(newValue)}
            format={DateFormat}
            sx={{ width: '100%' }}
          />
        </Grid>
        {hasFundingBody && (
          <Grid item xs={4} md={4} lg={columnWidthLg}>
            <Autocomplete
              multiple
              value={fundingBodyOptions.filter((option) => fundingBodyId.includes(option.value))}
              onChange={(_, newValue): void => setFundingBodyId(newValue.map((option) => option.value))}
              options={fundingBodyOptions}
              renderInput={(params): JSX.Element => <TextField {...params} label="Funding Body" />}
              size="small"
              fullWidth
            />
          </Grid>
        )}
        {hasTechOrderType && (
          <Grid item xs={4} md={4} lg={columnWidthLg}>
            <Autocomplete
              multiple
              value={techTypeOptions.filter((option) => orderTypeId.includes(option.value))}
              onChange={(_, newValue): void => setOrderTypeId(newValue.map((option) => option.value))}
              options={techTypeOptions}
              renderInput={(params): JSX.Element => <TextField {...params} label="Order Type" />}
              size="small"
              fullWidth
            />
          </Grid>
        )}
        {hasEquipmentDeliveryDate && (
          <Grid item xs={4} md={4} lg={columnWidthLg}>
            <DatePicker
              label="Equipment Delivery Date"
              value={equipmentDeliveryDate}
              onChange={(newValue): unknown => setEquipmentDeliveryDate(newValue)}
              format={DateFormat}
              sx={{ width: '100%' }}
            />
          </Grid>
        )}
      </Grid>

      <WhyDisabled
        title={`Maximum KPI export period is ${MaxDifferenceInDays} days. The start date should not be greater than the end date.`}
        disabled={!isValidRange}
      >
        <OpenFile path={path} text="Download" variant="outlined" disabled={!isValidRange} fullWidth />
      </WhyDisabled>
    </Box>
  );
};
